/* border-radius: 1000; */
.popup > div {
    padding-top: 10px;
}
.popup-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-top: 10px;
}
.popup-message-container > * {
    padding-top: 10px;
}

.popup-message {
    color: rgba(0, 0, 0, 0.87);
    font-size: 23px;
    font-weight: bold;
}
