.footer {
    width: 100%;
    background: #ffffff;
}

.footer-background {
    height: 100%;
    z-index: 1;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

.footer-links {
    width: 100%;
    box-sizing: border-box;
    padding: 3rem 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-columns {
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-column {
    margin-top: 1rem;
    display: flex;
    width: 150px;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media (max-width: 767px) {
    .footer-links {
        flex-direction: column;
        padding: 1rem 0;
    }

    .footer-columns {
        width: 100%;
        flex-direction: column;
    }

    .footer-column {
        width: 100%;
    }
}

.footer-column-title {
    font-weight: bold !important;
}

.footer-column-title + .footer-use-links {
    margin-top: 0.5rem;
}

.footer-use-links {
    display: flex;
    flex-direction: column;
}

.footer-map {
    width: 50%;
    background: #f5f5f5;
}

.footer-privacy {
    position: relative;
    background: #444444;
    color: #ffffff;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-privacy-background {
    background: #444444;
    height: 100%;
    position: absolute;
    z-index: -1;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

.footer-privacy-link + .footer-privacy-link {
    margin-left: 1rem;
}
