.complete-account-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom right,
        #ffffff 50%,
        #f5f5f5 50%
    );
}

.complete-account-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.complete-account-container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.complete-account-form {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 3rem;

    border-radius: 0px;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    background-color: white;
    z-index: 1;
}

.complete-account-logo {
    width: 200px;
}

@media screen and (max-width: 600px) {
    .complete-account-background {
        background: #ffffff;
    }

    .complete-account-page {
        min-height: 100vh;
        background-color: white;
    }

    .complete-account-container {
        background-image: none;
    }

    .complete-account-form {
        box-shadow: none;
        padding: 1rem;
    }

    .complete-account-logo {
        width: 180px;
    }
}
