.reffy-form {
    min-width: 100%;
    width: 100%;
}

.reffy-form-field {
    margin: 1em;
}

.reffy-form-table {
    width: 100%;
}

.reffy-form-field-title {
    margin-bottom: 0.75em !important;
}

.reffy-form-stepper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reffy-form-progress-buttons {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.reffy-form-file-upload-input {
    display: none;
}
