.dashboard-home-copy-link {
    width: 100%;
    display: block;
    align-items: center;
}

.youtube-video-dashboard {
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 35px;
}

.youtube-video-dashboard > div {
    position: relative;
    padding-bottom: 56.27198%;
    height: 0px;
}
  
.youtube-video-dashboard iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    
}
