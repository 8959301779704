.login-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom right,
        #ffffff 50%,
        #f5f5f5 50%
    );
}

.login-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo {
    width: 200px;
}

.login-divider {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
}

.login-container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 100%;
    box-sizing: border-box;
    padding: 3rem;

    border-radius: 0px;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    background-color: white;
    z-index: 1;
}

.login-main {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-left > .login-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    float: right;
}

.login-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-right > .login-main > .login-title {
    font-size: 40px;
}

.login-right > .login-main > .login-subtitle {
    margin-top: 1rem;
    color: #444444;
}

.login-err {
    color: #d32f2f;
}

@media screen and (max-width: 600px) {
    .login-logo {
        width: 180px;
    }
}
