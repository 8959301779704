html,
body {
    background: #f5f5f5;
    padding: 0;
    margin: 0;
    font-family:
        GTPressura Standard,
        Tahoma,
        Verdana,
        Segoe,
        sans-serif;
    font-style: 'normal';
    -webkit-font-smoothing: antialiased;
    z-index: -10;
}

html {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

body,
#root,
#scroller {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    position: relative;
}

@media screen and (max-width: 1200px) {
    body,
    #root {
        max-width: 100vw;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
}

@font-face {
    font-family: 'GT Pressura Standard';
    src:
        url('fonts/GT Pressura/GT-Pressura-Standard-Regular.woff')
            format('woff'),
        /* Modern Browsers */
            url('fonts/GT Pressura/GT-Pressura-Standard-Regular.ttf')
            format('truetype'); /* Safari, Android, iOS */
    font-weight: regular;
}

@font-face {
    font-family: 'GT Pressura Standard';
    src:
        url('fonts/GT Pressura/GT-Pressura-Standard-Bold.woff') format('woff'),
        /* Modern Browsers */
            url('fonts/GT Pressura/GT-Pressura-Standard-Bold.ttf')
            format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
} 
