.info-cards {
    margin-top: 71px;
    display: flex;
    flex-direction: column;
    background-color: #f9d37f;
    align-items: center;
    padding-bottom: 73px;
}

.info-cards-text {
    width: 70%;
    padding: 0px 1rem;
    margin-top: 73px;
    margin-bottom: 73px;
}

.info-cards-boxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-content: space-evenly;
}

.info-cards-box {
    width: 400px;
    height: 400px;
    background: #ffffff;
    padding: 2rem;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .info-cards-boxes {
        width: unset;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        margin-left: 0;
        margin-right: 0;
        padding: 0 1rem;
    }
    .info-cards-box {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        display: flex;
        background: white;
        flex-direction: column;
        padding: 1.5rem;
        box-sizing: border-box;
    }
}

@media (max-width: 600px) {
    .info-cards-boxes {
        height: auto;
        display: grid;
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
    .info-cards-box {
        height: auto;
        background: white;
        grid-column: 1 / -1;
        padding: 20px;
    }
}
