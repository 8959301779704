.dashboard-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px 50px 0px 50px;
    box-sizing: border-box;
    align-items: center;
}

.dashboard-nav > * + * {
    margin-left: 16px !important;
}

.dashboard-nav-logo {
    width: 150px;
    margin-right: 100px;
}

.dashboard-nav-logo:hover {
    cursor: pointer;
}

.dashboard-nav-reflink {
    margin-left: auto !important;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 500px;
}

.dashboard-nav-reflink-textfield {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    color: #999999;
    height: 50px;
}

.dashboard-nav-reflink-textfield-text {
    padding-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto !important;
}

.dashboard-nav-reflink-copy {
    margin-left: auto !important;
}

.dashboard-nav-options-button {
    padding: 0.5rem !important;
    min-width: 160px !important;
    height: 48px;
}

.dashboard-nav-account-button-write-now {
    margin-left: auto !important;
    color: #999999 !important;
    font-size: 14px !important;
    width: 60px;
    height: 48px;
    padding: 0.5rem !important;
}

.dashboard-nav-account-button-help {
    color: #999999 !important;
    font-size: 14px !important;
    width: 60px;
    height: 48px;
    padding: 0.5rem !important;
}

.dashboard-nav-account-button-account {
    color: #999999 !important;
    font-size: 14px !important;
    min-width: 130px;
    height: 48px;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    gap: 1rem;
}
.dashboard-home {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dashboard-intro-prompt-helper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.dashboard-intro-prompt-helper-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 2rem;
    margin-bottom: 1rem;
}

.dashboard-intro-prompt-helper-box {
    display: flex;
    background: white;
    flex-direction: column;
    padding: 15px 25px 15px 25px;
    box-sizing: border-box;
}

.dashboard-intro-prompt-helper-box > button {
    margin-top: 1rem;
    width: 140px !important;
    padding: 0.5rem;
}

.dashboard-intro-prompt {
    text-align: center;
    margin-top: 20px !important;
    width: 80%;
}

.dashboard-request-cards {
    width: 100%;
    margin-top: 3rem !important;
    display: flex;
    justify-content: center;
}

.dashboard-completed-request-cards {
    margin-top: 27px;
    width: 85%;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-navigator {
    width: 100%;
    position: relative;
    min-height: 85px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px 30px;
}

.dashboard-navigator-background {
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    background-color: #ffffff;
}
.dashboard-more-options-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.dashboard-more-options-popup-window {
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.18);
    width: 550px;
    height: auto;

    padding-bottom: 50px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-more-options-popup-window-methods {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.dashboard-more-options-popup-email {
    width: 100%;
    max-height: 100px;
    display: flex;
    flex-direction: column;
}

.dashboard-more-options-popup-close {
    position: relative;
    right: -250px;
}

.dashboard-divider-container {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
}

.dashboard-divider {
    width: 30%;
    height: 1px;
    color: #444444;
}

@media screen and (min-width: 1201px) {
    .dashboard-help-drawer > div {
        position: relative !important;
    }
}

@media screen and (max-width: 1200px) {
    .dashboard-nav-reflink {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .dashboard-nav-reflink-textfield {
        width: 100%;
        min-height: 50px;
        display: flex;
        background-color: white;
        justify-content: center;
        align-items: center;
        color: #999999;
    }

    .dashboard-nav-reflink-button {
        width: 130px;
        padding: 1rem !important;
    }

    .dashboard-more-options-popup-window {
        position: relative;
        background: rgb(255, 255, 255);
        border-radius: 0px;
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.18);

        box-sizing: border-box;
        padding: 1rem;

        width: 100vw;
        min-height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .dashboard-completed-request-cards {
        margin-top: 1rem;
    }

    .dashboard-intro-prompt {
        width: 100%;
        margin-top: 15px !important;
    }

    .dashboard-intro-prompt-helper {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .dashboard-intro-prompt-helper-boxes {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 10px;
        padding: 0 1rem 0 1rem;
    }

    .dashboard-intro-prompt-helper-box {
        display: flex;
        background: white;
        flex-direction: column;
        padding: 15px 25px 15px 25px;
        box-sizing: border-box;
    }

    .dashboard-intro-prompt-helper-box > button {
        margin-top: 1rem;
        width: 140px !important;
        padding: 1rem;
    }
    .dashboard-more-options-popup-window-methods {
        gap: 0px;
    }
    .dashboard-request-cards {
        margin-top: 1rem !important;
    }

    .dashboard-completed-request-cards {
        width: 85%;
    }
}

@media screen and (max-width: 600px) {
    .dashboard-intro-prompt-helper-boxes {
        height: auto;
        display: grid;
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
        padding: 0 1rem 0 1rem;
    }

    .dashboard-intro-prompt-helper-box {
        background: white;
        grid-column: 1 / -1;
        padding: 20px;
    }
    .dashboard-intro-prompt {
        width: 100%;
        text-align: left;
        margin-top: 1rem !important;
    }
    .dashboard-completed-request-cards {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        justify-content: center;
    }
    .dashboard-completed-request-card {
        display: flex;
        justify-content: center;
    }
    .dashboard-completed-request-cards {
        width: 100%;
    }
    .dashboard-divider {
        width: 20%;
    }
}
