.reference-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reference-card-pfp-wrapper:hover {
    cursor: pointer;
}

.reference-card-pfp {
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid rgb(255, 255, 255);
    z-index: 1;
}

.reference-card-body {
    width: 400px;
    height: 344px;
    background-color: white;
    margin-top: -100px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.reference-card-body-text {
    width: 100%;
    color: #999999 !important;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.reference-card-body-text-applications {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: keep-all;
    text-overflow: ellipsis;
    white-space: normal;
}

.reference-card-button {
    position: absolute !important;
    bottom: 30px !important;
}

.mini-reference-card {
    min-width: 350px;
    display: flex;
    align-items: center;
}

.mini-reference-card-button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.mini-reference-card-body {
    background-color: white;
    width: 300px;
    height: 140px;
    z-index: 0;
    overflow: hidden;
    margin-left: -50px;
}

.mini-reference-card-body-text {
    margin-left: 57px;
    padding: 0 5% 0 0%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.due_date_icon {
    overflow: visible;
    position: absolute;
    right: 5%;
    top: 0%;
    height: 30px;
    width: 30px;
    border: 1px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 10 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .reference-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reference-card-pfp {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 5px solid rgb(255, 255, 255);
        z-index: 1;
    }

    .reference-card-body {
        width: 250px;
        height: 240px;
        background-color: white;
        margin-top: -50px;
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        position: relative;
    }

    .reference-card-body-text {
        margin-top: 3.5rem;
        margin-bottom: auto;
    }

    .reference-card-button {
        position: absolute !important;
        bottom: 15px !important;
    }

    .mini-reference-card {
        width: 90%;
        max-width: 400px;
        min-width: 0;
        height: 100px;
        background-color: white;
    }

    .mini-reference-card-body {
        background-color: white;
        min-width: 100%;
        width: 100%;
        z-index: 0;
        overflow: hidden;
        margin: 0;
        text-align: left;
        height: 100%;
    }

    .mini-reference-card-body-text {
        margin-left: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        padding-right: 4rem;
    }

    .mini-reference-card-button {
        width: 100% !important;
        height: 100%;
        max-width: 100%;
        padding: 0 !important;
    }

    .mini-reference-card-pfp {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .mini-reference-card-pfp-avatar {
        width: 50px !important;
        height: 50px !important;
    }
}
