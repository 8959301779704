.applicant-experience-header-name {
    display: flex;
    gap: 1rem;
}

.applicant-experience-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.applicant_ribbon {
    width: 200px;
    width: 250px;
    height: 50px;
    background-color: #444444;

    position: absolute;
    left: -20px;
    z-index: 1;
    top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: start;
}
.applicant_ribbon::before {
    z-index: -150;
    content: '';
    position: absolute;
    top: -14px;
    left: 6px;
    transform: rotate(-45deg);
    border-style: solid;
    border-width: 14px 14px 14px 14px;
    border-color: #8f8f8f transparent transparent transparent;
}

.applicant_ribbon::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 25px 0 25px 25px;
    border-color: transparent transparent transparent #444444;
}
