.reffy-confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.reffy-confirmation-popup-window {
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.18);
    width: 550px;

    padding-bottom: 15px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
