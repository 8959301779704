.choose-subscription-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom right,
        #ffffff 50%,
        #f5f5f5 50%
    );
}

.choose-subscription-page {
    position: relative;
    width: 100%;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
}

.choose-subscription-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.choose-subscription-box {
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding: 25px 0px 20px 0px;
    background: #ffffff;
    box-sizing: border-box;
}

.choose-subscription-box + .choose-subscription-box {
    padding: 60px 0px 20px 0px;
}

.choose-subscription-box-content {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: 33px;
    margin-right: 33px;
    flex-grow: 1;
    padding-bottom: 1rem;
}

.choose-subscription-box-content-price {
    display: flex;
    flex-direction: column;
}

.choose-subscription-label {
    width: 100%;
    height: 40px;
    background: rgb(249, 211, 127, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}

@media (max-width: 1200px) {
    .choose-subscription-page {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .choose-subscription-box {
        width: 85%;
        height: auto;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 0px 0px;
    }

    .choose-subscription-box + .choose-subscription-box {
        padding: 40px 0px 20px 0px;
    }

    .choose-subscription-box-content {
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 1rem;
        position: initial;
        width: 90%;
    }
}
