.intake-logo {
    width: 150px;
}

/* .reffy-form-field textarea {
    padding-top:8px;
} */

/* .css-s77cxy-MuiInputBase-root-MuiFilledInput-root.Mui-focused input{
    margin-top:20px;
}  */

.reffy-form-table-names {
    width: 50%;
}

.reffy-form-table-names > * {
    font-size: 20px !important;
    font-weight: bold;
}
.reffy-form-table-values > * {
    font-size: 20px !important;
}
