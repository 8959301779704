.support-stack {
    width: 60%;
}
.support-button {
    width: 200px;
    align-self: center;
}

@media (max-width: 600px) {
    .support-stack {
        width: 95%;
    }
    .support-button {
        width: 100%;
    }
}

.youtube-video-support {
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 8px;
}

.youtube-video-support > div {
    position: relative;
    padding-bottom: 56.27198%;
    height: 0px;
}
  
.youtube-video-support iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    
}


