.loading-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-logo {
    position: absolute;
    width: 80px;
    height: 80px;
    z-index: 1;
}
