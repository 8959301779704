.home-1 {
    position: relative;
    background: #ffffff;

    width: 90%;

    margin-left: 5%;
    margin-right: 5%;

    margin-top: 52px;
    margin-bottom: 52px;

    box-sizing: border-box;
    padding-top: 3rem;
    padding-bottom: 3rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-1-text {
    width: 40%;
    padding-left: 4rem;
}

.home-1-buttons {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.home-1-buttons > a + p {
    margin-left: 1rem;
}

.home-1-image {
    position: absolute;
    right: 5%;
    width: 47%;
    height: 120%;
}

.home-1-image-img {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0);
    aspect-ratio: 609 / 454;
    overflow: hidden;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .home-1 {
        padding-left: 0;
        padding-top: 0;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0;
        padding-bottom: 0;
    }

    .home-1-image {
        position: relative;
        right: 0%;
        max-width: 100%;
        width: 100%;
    }

    .home-1-image-img {
        position: relative;
        right: 0%;
        max-width: 100%;
        border: none;
    }

    .home-1-text > .home-1-buttons {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .home-1-text {
        margin-top: 2rem;
        width: 85%;
        padding: 0px 1rem;
    }
}

.home-2 {
    margin-top: 132px;
    width: 100%;
    background-image: url('../../../public/assets/images/home/home-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-2-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.home-2-overlay-text {
    width: 60%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .home-2 {
        margin-top: 0;
    }

    .home-2-overlay-text {
        width: 85%;
    }
}

.home-3 {
    width: 100%;
}

.home-3-text {
    width: 60%;
    margin-top: 73px;
    margin-left: 10%;
}

@media (max-width: 1200px) {
    .home-3-text {
        width: 85%;
        margin-top: 73px;
        /* margin-left: 10%; */
    }
}

.home-4 {
    margin-top: 71px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
}

.home-4-box {
    width: 400px;
    height: 400px;
    background: #ffffff;
    padding: 70px 33px 0 33px;
    box-sizing: border-box;
}

.home-4-box + .home-4-box {
    margin-left: 40px;
}

@media (max-width: 1200px) {
    .home-4 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        margin-left: 0;
        margin-right: 0;
        padding: 0 1rem;
    }

    .home-4-box {
        width: 100%;
        height: auto;
        margin: 0 !important;
        display: flex;
        background: white;
        flex-direction: column;
        padding: 15px 25px 15px 25px;
        box-sizing: border-box;
    }
}

@media (max-width: 600px) {
    .home-4 {
        height: auto;
        display: grid;
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }

    .home-4-box {
        background: white;
        grid-column: 1 / -1;
        padding: 20px;
    }
}

.home-5 {
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-5 > .home-5-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-5-buttons > a + p {
    margin-left: 1rem;
}

@media (max-width: 1200px) {
    .home-5 > .home-5-buttons {
        width: 85%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        align-items: center;
    }
}

.home-6 {
    margin-top: 63px;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    padding-left: 120px;
    padding-right: 85px;
    padding-bottom: 63px;
    box-sizing: border-box;
}

.home-6-text {
    width: 571px;
    margin-top: 125px;
    margin-right: 60px;
}

.home-6-img {
    width: 50%;
    margin-top: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-6-img > img {
    width: 100%;
    object-fit: fill;
}

/* @media (max-width: 767px) {   */
@media (max-width: 1200px) {
    .home-6 {
        margin-top: 0;
        align-items: center;
        justify-content: center;

        padding: 0;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0;
    }

    .home-6-img {
        margin-top: 0;
        position: relative;
        right: 0%;
        max-width: 100%;
        width: 100%;
    }

    .home-6-text {
        margin-top: 2rem;
        width: 85%;
        padding: 0px 1rem;
        margin-right: 0;
        margin-bottom: 2rem;
    }
}

.home-chat-bubble-img {
    width: 100%;
    display: block;
}

.home-7 {
    width: 100%;
    height: 181px;
    background-image: url('../../../public/assets/images/home/home-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
}

.home-7-desktop {
    width: 100%;
    height: 600px;
    background-image: url('../../../public/assets/images/home/home-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
}

.home-7-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.home-7-overlay-holder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    font-weight: normal;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
}

@media (max-width: 1200px) {
    .home-7-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
    }

    .home-7-overlay-holder {
        width: 85%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        padding: 0;
    }
}

.youtube-video-mobile-1 {
    margin-top: 71px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.27198%;
}

.youtube-video-mobile-1 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.youtube-video-desktop-1 {
    max-width: 1200px;
    margin: 0px auto;
    margin-top: 50px;
}

.youtube-video-desktop-1 > div {
    position: relative;
    padding-bottom: 56.27198%;
    height: 0px;
}
  
.youtube-video-desktop-1 iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}