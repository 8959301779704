.magage-top-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.magage-top-row-buttons {
    display: flex;
    gap: 1rem;
    margin-left: auto;
    margin-right: 1rem;
}

@media screen and (max-width: 600px) {
    .magage-top-row {
        flex-direction: column;
        gap: 1rem;
    }
    .magage-top-row-buttons {
        margin: 0 auto;
    }
}
