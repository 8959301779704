.navbar {
    margin-left: 5%;
    margin-right: 5%;

    box-sizing: border-box;
    padding: 0.5rem;
}

.navbar-stack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
}

.navbar-logo {
    width: 150px;
}

.navbar-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}


.navbar-image-text {
    align-items: center;
    justify-content: left;
    font-size: 15px;
    color: #444444;
}

.navbar-buttons > a {
    font-weight: bold;
}

.navbar-buttons > .MuiButton-outlined {
    border: 2px solid #444444;
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 0;
    }
}
