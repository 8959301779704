.pricing-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pricing-boxes-container {
    box-sizing: border-box;
    width: 960px;
    margin-top: 2rem;
}

.pricing-6-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
}

.pricing-6-box {
    display: flex;
    flex-direction: row;
    width: 960px;
    position: relative;
    padding: 25px 0px 20px 0px;
    background: #ffffff;
    box-sizing: border-box;
}

.pricing-6-box-content {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: 33px;
    margin-right: 33px;
    flex-grow: 1;
}

.pricing-6-buttons {
    width: auto;
    height: auto;
    position: absolute;
    right: -50px;
}

.pricing-6-box-content-price {
    display: flex;
    flex-direction: column;
}

.pricing-back-button {
    position: relative;
    align-self: flex-start;
    padding-left: 0 !important;
    margin: 0 0 1rem 0 !important;
}

@media (max-width: 1200px) {
    .pricing-6 {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .pricing-boxes-container {
        width: 75%;
    }

    .pricing-6-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .pricing-6-box {
        width: 100%;
        padding-top: 5%;
        height: auto;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 20px 0px;
    }

    .pricing-6-box + .pricing-6-box {
        padding: 4px 0px 20px 0px;
    }

    .pricing-6-buttons {
        width: 100%;
        height: auto;
        position: relative;
        right: 0%;
        top: 0%;
    }

    .pricing-6-box-content {
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 1rem;
        position: initial;
        width: 90%;
    }

    .pricing-6-box-content-price {
        display: flex;
        flex-direction: column;
    }

    .pricing-back-button {
        margin: 0 0 1rem 0 !important;
    }
}

@media (max-width: 600px) {
    .pricing-boxes-container {
        width: 95%;
    }
}
