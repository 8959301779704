.law_funnel-1 {
    position: relative;
    background: #ffffff;

    width: 90%;

    margin-left: 5%;
    margin-right: 5%;

    margin-top: 52px;
    margin-bottom: 52px;

    box-sizing: border-box;
    padding-left: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.law_funnel-1-text {
    width: 40%;
}

.law_funnel-1-buttons {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.law_funnel-1-buttons > a + p {
    margin-left: 1rem;
}

.law_funnel-1-image {
    position: absolute;
    right: 4%;
    width: 50%;
    height: 110%;
}

.law_funnel-1-image-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 609 / 454;
    overflow: hidden;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .law_funnel-1 {
        padding-left: 0;
        padding-top: 0;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        margin: 0;
        padding-bottom: 1rem;
    }

    .law_funnel-1-image {
        position: relative;
        right: 0%;
        width: 100%;
        max-width: 100%;
    }

    .law_funnel-1-image-img {
        border: none;
    }

    .law_funnel-1-text > .law_funnel-1-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 0;
    }

    .law_funnel-1-text {
        margin-top: 3rem;
        width: 85%;
        padding: 0px 1rem;
    }

    .law_funnel-1-buttons > a + p {
        margin-left: 0;
    }
}

.law_funnel-3 {
    width: 70%;
    margin-top: 3rem;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1200px) {
    .law_funnel-3 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .law_funnel-3-text {
        width: 85%;
        padding: 0px 1rem;
    }
}

.law_funnel-4 {
    margin-top: 71px;
    display: flex;
    flex-direction: column;
    background-color: #f9d37f;
    align-items: center;
    /* height: 555px; */
    padding-bottom: 73px;
}

.law_funnel-4-text {
    width: 70%;
    padding: 0px 1rem;
    margin-top: 73px;
    margin-bottom: 73px;
}

@media (max-width: 1200px) {
    .law_funnel-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 3rem;
        padding-bottom: 3rem;
    }

    .law_funnel-4-text {
        width: 85%;
        padding: 0px 1rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.law_funnel-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 73px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 73px;
}

.law_funnel-5-text > .law_funnel-1-buttons {
    justify-content: center;
}

.law_funnel-5-text {
    text-align: center;
}

@media (max-width: 1200px) {
    .law_funnel-5 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 0;
    }

    .law_funnel-5-text {
        width: 85%;
        padding: 0px 1rem;
    }

    .law_funnel-5-text > .law_funnel-1-buttons {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .law_funnel-5-text {
        text-align: left;
    }
}

.law_funnel-6 {
    margin-top: 71px;
    margin-bottom: 1rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}

.law_funnel-6-text {
    margin-top: 71px;
    width: 70%;
    padding: 0px 1rem;
    text-align: center;
}
.law_funnel-6-form {
    margin-top: 1rem;
    width: 50%;
    padding: 0px 1rem;
}

@media (max-width: 1200px) {
    .law_funnel-6 {
        margin-top: 3rem;
    }
    .law_funnel-6-text {
        width: 85%;
        padding: 0px 1rem;
        margin-top: 3rem;
    }

    .law_funnel-6-form {
        width: 90%;
        padding: 0px 1rem;
    }

}

@media (max-width: 600px) {
    .law_funnel-6-text {
        text-align: left;
    }
    
}


.youtube-video-law {
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 73px;
}

.youtube-video-law > div {
    position: relative;
    padding-bottom: 56.27198%;
    height: 0px;
}
  
.youtube-video-law iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

@media (max-width: 1200px) {
    .youtube-video-law {
        margin-top: 3rem;
    }
}