.not-found-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.not-found-button {
    background: #f9d37f;
    border: 0;
    border-radius: 50%;
    box-shadow: inset -0.125rem -0.25rem rgba(0, 0, 0, 0.4);
    height: 6rem;
    width: 6rem;
    position: relative;
    z-index: 1;
}

.not-found-button::before {
    content: '';
    border-radius: 50px;
    box-shadow: inset 0.0625rem 0.125rem rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 4rem;
    width: 4rem;
}

.not-found-button::after {
    content: '';
    border-radius: 50%;
    box-shadow:
        2rem 2rem #462227,
        3.5rem 2rem #462227,
        2rem 3.5rem #462227,
        3.5rem 3.5rem #462227,
        2.05rem 2.05rem rgba(0, 0, 0, 0.4),
        3.55rem 2.05rem rgba(0, 0, 0, 0.4),
        2.05rem 3.55rem rgba(0, 0, 0, 0.4),
        3.55rem 3.55rem rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 0.6rem;
    width: 0.6rem;
}

.not-found-button:hover {
    transform: scale(1.2, 1.2);
}
