.user-info-1 {
    position: relative;
    background: #ffffff;

    width: 90%;

    margin-left: 5%;
    margin-right: 5%;

    margin-top: 52px;
    margin-bottom: 52px;

    box-sizing: border-box;
    padding-top: 3rem;
    padding-bottom: 3rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-info-1-text {
    width: 40%;
    padding-left: 4rem;
}

.user-info-1-buttons {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.user-info-1-buttons > a + p {
    margin-left: 1rem;
}

.user-info-1-image {
    position: absolute;
    right: 5%;
    width: 47%;
    height: 120%;
}

.user-info-1-image-img {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(255, 255, 255);
    aspect-ratio: 609 / 454;
    overflow: hidden;
    object-fit: cover;
}

.user-info-box-img {
    height: 100px;
    width: 100px;
    align-self: center;
    transform: translateX(-25px);
}

@media (max-width: 1200px) {
    .user-info-1 {
        padding-left: 0;
        padding-top: 0;
        flex-direction: column-reverse;
        position: relative;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0;
    }

    .user-info-1-image {
        position: relative;
        right: 0%;
        max-width: 100%;
        width: 100%;
    }

    .user-info-1-image-img {
        border: none;
    }
    .user-info-1-text {
        margin-top: 2rem;
        width: 85%;
        padding: 0px 1rem;
    }
}

.user-info-3 {
    width: 70%;
    margin-top: 4rem;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1200px) {
    .user-info-3 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-info-3-text {
        width: 85%;
        padding: 0px 1rem;
    }
}

.user-info-4 {
    margin-top: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 555px;
    position: relative;
    z-index: 1;
}
.user-info-4-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-info-4-background {
    background-color: #f9d37f;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

@media (max-width: 1200px) {
    .user-info-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}

.user-info-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 4rem;
    margin-left: 15%;
    margin-right: 15%;
}

.user-info-5-text > .user-info-1-buttons {
    justify-content: center;
}
.user-info-5-text {
    text-align: center;
}

@media (max-width: 1200px) {
    .user-info-5 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-info-5-text {
        width: 85%;
        padding: 0px 1rem;
    }
    .user-info-5-text > .user-info-1-buttons {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 600px) {
    .user-info-5-text {
        text-align: left !important;
    }
}

.user-info-6 {
    margin-top: 4rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-info-6-boxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.user-info-6-box {
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 440px;
    padding-top: 40px;
    background: #ffffff;
}

.user-info-6-box-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: 33px;
    flex-grow: 1;
}

.user-info-6-box > .user-info-1-buttons {
    margin-bottom: 2rem;
}

@media (max-width: 1200px) {
    .user-info-6 {
        display: grid;
    }

    .user-info-6-boxes {
        width: unset;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 1rem;
        gap: 1rem;
    }
    .user-info-6-box {
        width: auto;
        max-width: 480px;
        height: 100%;
    }
    .user-info-6-box > .user-info-1-buttons {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
}

@media (max-width: 600px) {
    .user-info-6 {
        margin-top: 71px;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .user-info-6-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .user-info-6-box {
        width: 100%;
    }

    .user-info-6-box-content {
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 1rem;
    }

    .user-info-6-box + .user-info-6-box {
        margin-left: 0px;
    }
}

.user-info-7 {
    width: 100%;
    height: fit-content;
    min-height: 180px;
    background-image: url('../../../../public/assets/images/home/home-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.user-info-7-overlay {
    width: 100%;
    height: fit-content;
    min-height: 180px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
}

.user-info-7-overlay-holder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    font-weight: normal;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    gap: 2rem;
}

@media (max-width: 1200px) {
    .user-info-7-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
    }
    .user-info-7-overlay-holder {
        width: 85%;
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 0.5rem;
    }
    
}


.youtube-video-user {
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 73px;
}

.youtube-video-user > div {
    position: relative;
    padding-bottom: 56.27198%;
    height: 0px;
}
  
.youtube-video-user iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

@media (max-width: 1200px) {
    .youtube-video-user {
        margin-top: 3rem;
    }
}
